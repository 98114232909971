<template>
  <div class="systemNotification">
    <!-- 消息通知场景菜单 -->
    <div class="menu">
      <notification-menu
        v-if="menuData1"
        title="消息通知场景"
        :menuData="menuData1"
        :defaultKey="sceneId"
        @add="addScene"
        @del="delScene"
        @menuItemClick="sceneMenuClick"
      >
        <span slot="operation" @click="EditScene">编辑</span>
      </notification-menu>
    </div>
    <div class="content">
      <!-- 场景标识 -->
      <div class="content_key">
        <div>
          <span class="scenarioKey"> 场景标识（scenarioKey）</span>
          <span v-if="!isModify" class="editKey"
            >{{ scenarioKey
            }}<a-icon
              type="edit"
              style="
                color: #1890ff;
                cursor: pointer;
                font-size: 20px;
                margin-left: 10px;
              "
              @click="EditKey"
          /></span>
          <span v-else class="editKey">
            <a-input v-model="scenarioKey"></a-input>
            <a-icon
              type="check"
              style="
                font-size: 20px;
                color: #68b468;
                margin-right: 15px;
                margin-left: 15px;
              "
              @click="modifyKey"
            />
            <a-icon
              type="close"
              style="font-size: 20px; color: #ff6868"
              @click="cancelEdit"
            />
          </span>
        </div>
      </div>
      <!-- 添加、编辑场景弹窗 -->
      <scene
        :sceneVisible.sync="sceneVisible"
        :sceneData="sceneData"
        :groupId="groupId"
        v-if="sceneVisible"
        @refresh="refresh"
      ></scene>
      <!-- 删除场景弹窗 -->
      <del-scene
        :delSceneVisible.sync="delSceneVisible"
        :sceneList="menuData1"
        :sceneId="sceneId"
        v-if="delSceneVisible"
        @refresh="refresh"
      ></del-scene>
      <!-- 场景内容 -->
      <div class="content_tab">
        <a-tabs
          @change="tabChange"
          pagination="false"
          :defaultActiveKey="tabKey ? tabKey : '1'"
        >
          <a-tab-pane key="1" tab="渠道">
            <a-button type="primary" class="button" @click="addChannel"
              >添加渠道</a-button
            >

            <!-- <edit-template
              :templateVisible.sync="templateVisible"
              :templateData="templateData"
              :variableData="variableData"
              v-if="templateVisible"
            ></edit-template> -->
            <a-table
              :columns="columns1"
              :data-source="chancelData"
              :pagination="false"
              :scroll="{ y: scrollHeight(400) }"
            >
              <!-- 通知方式 -->
              <div slot="channel" slot-scope="text, record">
                <!-- <span v-if="text == 'EMAIL'">邮箱</span>
                <span v-if="text == 'HUAWEI_SMS'">华为云短信</span>
                <span v-if="text == 'TENCENT_SMS'">腾讯云短信</span>
                <span v-if="text == 'WX_MP'">微信公众号</span> -->
                <span>{{ ChannelName[text] }}</span>
              </div>
              <!-- 模板 -->
              <div slot="content" slot-scope="text">
                <span>{{ text ? text : '---' }}</span>
              </div>
              <!-- 开关 -->
              <div slot="status" slot-scope="text, record, index">
                <!-- :checked="record.status == 'DISABLED' ? false : true" -->
                <a-switch
                  :checked="record.switch"
                  @change="switchChange($event, record, index)"
                />
              </div>
              <!-- 编辑 -->
              <div slot="operation" slot-scope="text, record">
                <a @click="editTemplate(record)">编辑模板</a>
              </div>
            </a-table>
            <!-- 编辑、添加模板弹窗 -->
            <edit-template
              :templateVisible.sync="templateVisible"
              :templateData="templateData"
              :variableData="variableData"
              :scenarioId="sceneId"
              v-if="templateVisible"
              @refresh="refresh"
              type="system"
            ></edit-template>
          </a-tab-pane>
          <a-tab-pane key="2" tab="变量">
            <a-button type="primary" class="button" @click="addVariable"
              >添加变量</a-button
            >
            <!-- 添加变量 -->
            <add-variable
              :visible.sync="variableVisible"
              :scenarioId="sceneId"
              v-if="variableVisible"
              @refresh="refresh"
            ></add-variable>
            <a-table
              :columns="columns2"
              :data-source="variableData"
              :pagination="false"
              :scroll="{ y: scrollHeight(400) }"
            >
              <div slot="operation" slot-scope="text, record">
                <a @click="delVariable(record)">删除</a>
              </div>
            </a-table>
          </a-tab-pane>
          <a-tab-pane key="3" tab="个性化">
            <a-button type="primary" class="button" @click="addIndividuation"
              >添加</a-button
            >
            <a-table
              :columns="columns3"
              :data-source="individuationData"
              :pagination="false"
              :scroll="{ y: scrollHeight(400) }"
            >
              <!-- 短信签名 -->
              <div slot="sms" slot-scope="text, record">
                {{ record.sms.tencentSignatureName }}
              </div>

              <!-- 邮件服务器 -->
              <div slot="smtp" slot-scope="text, record">
                {{ record.smtp.host + ':' + record.smtp.port }}
              </div>

              <!-- 公众号是否配置 -->
              <div slot="enable" slot-scope="text">
                {{ text ? '已配置' : '未配置' }}
              </div>

              <div slot="operation" slot-scope="text, record">
                <a
                  v-if="!record.scenarioIds.includes(sceneId)"
                  @click="allot(record)"
                  >分配</a
                >
                <a v-else @click="unassign(record)">取消分配</a>
                <a-divider type="vertical" />
                <a @click="editIndividuation(record)">编辑</a>
                <a-divider type="vertical" />
                <a @click="delIndividuation(record)">删除</a>
              </div>
            </a-table>
            <!-- 编辑个性化设置弹窗 -->
            <edit-individuation
              :individuationRecord="individuationRecord"
              :individuationVisible.sync="individuationVisible"
              :scenarioId="sceneId"
              v-if="individuationVisible"
              type="system"
              @refresh="refresh"
            ></edit-individuation>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>

    <!-- 使用store模块化 -->
    <!-- <div>{{ $store.getters['module/getName'] }}</div> -->
    <!-- <div>{{ $store.state.module.name }}</div> -->
  </div>
</template>

<script>
import NotificationMenu from './components/notificationMenu.vue'
import Scene from './modal/Scene.vue'
import delScene from './modal/delScene.vue'
import editTemplate from './modal/editTemplate.vue'
import editIndividuation from './modal/editIndividuation.vue'
import addVariable from './modal/addVariable.vue'
import getScrollHeight from '@/utils/scrollHeight.js'

const columns1 = [
  {
    title: '通知方式',
    key: 'channel',
    dataIndex: 'channel',
    scopedSlots: { customRender: 'channel' },
  },
  {
    title: '模板',
    key: 'content',
    dataIndex: 'content',
    scopedSlots: { customRender: 'content' },
  },
  {
    title: '状态',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    key: 'operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]

const columns2 = [
  {
    title: '字段',
    key: 'description',
    dataIndex: 'description',
    scopedSlots: { customRender: 'description' },
  },
  {
    title: '表达式',
    key: 'name',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '操作',
    key: 'operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
]

const columns3 = [
  {
    title: '应用ID',
    key: 'clientId',
    dataIndex: 'clientId',
    scopedSlots: { customRender: 'clientId' },
  },
  {
    title: '应用',
    key: 'clientName',
    dataIndex: 'clientName',
    scopedSlots: { customRender: 'clientName' },
  },
  {
    title: '短信签名',
    key: 'sms',
    dataIndex: 'sms',
    scopedSlots: { customRender: 'sms' },
  },
  {
    title: '邮件服务器',
    key: 'smtp',
    dataIndex: 'smtp',
    scopedSlots: { customRender: 'smtp' },
  },
  {
    title: '公众号配置信息',
    key: 'enable',
    dataIndex: 'enable',
    scopedSlots: { customRender: 'enable' },
    align: 'center',
  },
  {
    title: '操作',
    key: 'operation',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    align: 'center',
  },
]

const chancelData = [
  {
    informMethod: '站内信',
    template: '--',
    status: true,
  },
  {
    informMethod: '短信',
    template: '--',
    status: true,
  },
  {
    informMethod: '微信',
    template: '--',
    status: true,
  },
  {
    informMethod: '邮件',
    template: '--',
    status: true,
  },
]

const variableData = [
  {
    field: 'code',
    expression: '${code}',
  },
]

const individuationData = [
  {
    appId: '2364827',
    appName: 'NIUESS',
    smsName: 'NIUESS',
    email: 'info@qq.com',
    officialAccount: '已配置',
  },
]

export default {
  name: 'SystemNotification',
  inject: ['reload'],
  mixins: [getScrollHeight],

  components: {
    NotificationMenu,
    Scene,
    delScene,
    editTemplate,
    editIndividuation,
    addVariable,
  },
  data() {
    return {
      menuData1: [],
      columns1,
      chancelData,
      columns2,
      variableData,
      columns3,
      individuationData,
      scenarioKey: '234789672398475237498',
      isModify: false,
      sceneVisible: false,
      sceneData: {},
      delSceneVisible: false,
      templateVisible: false,
      individuationVisible: false,
      variableVisible: false,
      individuationRecord: {},
      groupId: '91000000000000000000',
      editPre: '',
      sceneId: '',
      tabKey: '',
      templateData: {},
      channelList: ['TENCENT_SMS', 'HUAWEI_SMS', 'WX_MP', 'EMAIL'],
      ChannelName: {
        TENCENT_SMS: '腾讯云',
        HUAWEI_SMS: '华为云',
        WX_MP: '微信公众号',
        EMAIL: '邮箱',
        JIGUANG: '极光',
        IM: '站内信',
      },
    }
  },
  created() {
    // this.tabKey = this.$store.state.notification.systemTabKey
    // this.sceneId = this.$store.state.notification.systemSceneMenuKey
    // this.scenarioKey = this.$store.state.notification.systemScenarioKey
    // if (this.$store.state.notification.systemSceneMenuKey) {
    //   this.getAllSceneList().then((res) => {
    //     this.sceneId = this.$store.state.notification.systemSceneMenuKey
    //     /* 判断场景是否已经被删除 */
    //     let sceneIdList = res.map((item) => {
    //       return (item = item.scenarioId)
    //     })
    //     if (sceneIdList.includes(this.sceneId)) {
    //       this.sceneData = res.filter((item) => {
    //         return item.scenarioId == this.sceneId
    //       })[0]
    //       this.scenarioKey = this.sceneData.scenarioKey
    //       this.getTemplateList(this.sceneId)
    //       this.getVariableList(this.sceneId)
    //       this.getCustomizationList(this.sceneId)
    //     } else {
    //       this.sceneId = sceneIdList[0]
    //       this.sceneData = res[0]
    //       this.scenarioKey = res[0].scenarioKey
    //       this.getTemplateList(this.sceneId)
    //       this.getVariableList(this.sceneId)
    //       this.getCustomizationList(this.sceneId)
    //     }
    //   })
    // } else {
    //   this.getAllSceneList().then(() => {
    //     this.sceneId = this.menuData1[0].scenarioId
    //     this.scenarioKey = this.menuData1[0].scenarioKey
    //     this.getTemplateList(this.sceneId)
    //     this.getVariableList(this.sceneId)
    //     this.getCustomizationList(this.sceneId)
    //   })
    // }

    this.init()
  },
  beforeDestroy() {
    /* 保留局部刷新前的位置状态 */
    this.savePosition()
  },
  methods: {
    init() {
      if (this.sceneId) {
        this.getAllSceneList().then((res) => {
          /* 判断场景是否已经被删除 */
          let sceneIdList = res.map((item) => {
            return (item = item.scenarioId)
          })
          if (sceneIdList.includes(this.sceneId)) {
            this.sceneData = res.filter((item) => {
              return item.scenarioId == this.sceneId
            })[0]
            this.scenarioKey = this.sceneData.scenarioKey
            this.getTemplateList(this.sceneId)
            this.getVariableList(this.sceneId)
            this.getCustomizationList(this.sceneId)
          } else {
            this.sceneId = sceneIdList[0]
            this.sceneData = res[0]
            this.scenarioKey = res[0].scenarioKey
            this.getTemplateList(this.sceneId)
            this.getVariableList(this.sceneId)
            this.getCustomizationList(this.sceneId)
          }
        })
      } else {
        this.getAllSceneList().then(() => {
          this.sceneId = this.menuData1[0].scenarioId
          this.scenarioKey = this.menuData1[0].scenarioKey
          this.getTemplateList(this.sceneId)
          this.getVariableList(this.sceneId)
          this.getCustomizationList(this.sceneId)
        })
      }
    },
    refresh() {
      this.templateVisible = false
      this.individuationVisible = false
      this.variableVisible = false
      this.sceneVisible = false
      this.delSceneVisible = false
      this.init()
    },
    /* 添加渠道 */
    addChannel() {
      this.templateData = {}
      this.templateVisible = true
    },
    /* 保留局部刷新前的位置状态方法 */
    savePosition() {
      let position = {
        sceneMenuKey: this.sceneId,
        tabKey: this.tabKey,
        scenarioKey: this.scenarioKey,
      }
      this.$store.commit('notification/savePosition2', position)
    },

    /* 获取全部场景 */
    getAllSceneList() {
      return new Promise((resolve, reject) => {
        this.$api
          .getNotificationScene(this.groupId)
          .then((res) => {
            if (res.code === 0) {
              this.menuData1 = res.data
              this.menuData1.forEach((item) => {
                this.$set(item, 'id', item.scenarioId)
              })
              resolve(res.data)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 根据场景获取变量列表 */
    getVariableList(scenarioId) {
      return new Promise((resolve, reject) => {
        this.$api
          .getVariableByScenarioId(scenarioId)
          .then((res) => {
            if (res.code === 0) {
              this.variableData = res.data
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 根据场景获取模板列表（渠道） */
    getTemplateList(scenarioId) {
      return new Promise((resolve, reject) => {
        this.$api
          .getTemplateByScenarioId(scenarioId)
          .then((res) => {
            if (res.code === 0 && res.data.length > 0) {
              this.chancelData = res.data
              this.chancelData.forEach((item) => {
                this.$set(item, 'switch', item.status != 'DISABLED')
              })
              resolve()
            } else if (res.code === 0 && res.data.length === 0) {
              /* 发送添加默认模板请求 */
              Promise.all(this.addTemplates(scenarioId)).then((res) => {
                /* 添加完成有，在此发起获取模板请求 */
                this.getTemplateList(scenarioId)
              })
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 获取客制化（个性化配置）列表数据 */
    getCustomizationList(scenarioId) {
      return new Promise((resolve, reject) => {
        this.$api
          .getCustomizationList(scenarioId)
          .then((res) => {
            if (res.code === 0) {
              this.individuationData = res.data
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 更新模板数据（状态） */
    updateTemplate(templateId, modifyData) {
      return new Promise((resolve, reject) => {
        this.$api
          .updateTemplate(templateId, modifyData)
          .then((res) => {
            if (res.code === 0) {
              this.$message.success('修改成功')
              resolve()
            }
          })
          .catch((err) => {
            this.$message.error('修改失败')
            console.log(err, '更新模板报错')
            reject()
          })
      })
    },
    /* 场景菜单点击 */
    sceneMenuClick(key) {
      this.sceneId = key
      /* 当前选中场景的数据 */
      this.sceneData = this.menuData1.filter((item) => {
        return item.scenarioId == key
      })[0]

      this.scenarioKey = this.sceneData.scenarioKey
      this.getTemplateList(key)
      this.getVariableList(this.sceneId)
      this.getCustomizationList(this.sceneId)
    },

    /* 更新个性化的分配场景 */
    updateCustomizationOfScene(data) {
      return new Promise((resolve, reject) => {
        this.$api
          .updateCustomization(data)
          .then((res) => {
            if (res.code === 0) {
              resolve()
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    /* 个性化关联场景 */
    allot(record) {
      /* 当前个性化已关联的场景id数据 */
      let { scenarioIds, ...data } = record
      scenarioIds = [...scenarioIds, this.sceneId]

      let _this = this
      let data2 = { ...data, scenarioIds }
      this.$confirm({
        title: '确定将该个性化配置分配给当前场景？',
        onOk() {
          _this
            .updateCustomizationOfScene(data2)
            .then(() => {
              _this.$message.success('分配成功')
              _this.refresh()
            })
            .catch((err) => {
              console.log(err)
              _this.$message.error('分配失败')
            })
        },
        onCancel() {},
      })
    },

    /* 取消个性化关联场景 */
    unassign(record) {
      let { scenarioIds, ...data } = record
      scenarioIds = scenarioIds.filter((item) => {
        return item != this.sceneId
      })
      let data2 = { ...data, scenarioIds }
      data2 = this.cleanEmpty(data2)
      let _this = this
      this.$confirm({
        title: '确定将该个性化配置分配给当前场景？',
        onOk() {
          _this
            .updateCustomizationOfScene(data2)
            .then(() => {
              _this.$message.success('取消分配成功')
              _this.refresh()
            })
            .catch(() => {
              _this.$message.error('取消分配失败')
            })
        },
        onCancel() {},
      })
    },

    cleanEmpty(object) {
      return Object.keys(object)
        .filter((key) => {
          return (
            object[key] !== ' ' &&
            object[key] !== null &&
            object[key] !== undefined &&
            object[key] !== ''
          )
        })
        .reduce((prev, curr) => {
          return { ...prev, [curr]: object[curr] }
        }, {})
    },

    /* 添加默认模板 */
    addDefaultTemplate(data) {
      return new Promise((resolve, reject) => {
        this.$api
          .addTemplateChannel(data)
          .then((res) => {
            if (res.code === 0) {
              resolve()
            }
          })
          .catch((err) => {
            reject()
          })
      })
    },
    /* 添加四个默认模板 */
    addTemplates(scenarioId) {
      return this.channelList.map((item) => {
        let data = {
          channel: item,
          content: '',
          scenarioId: scenarioId,
          status: 'DISABLED',
          thirdPartContent: '',
          thirdPartSignature: '',
          thirdPartTemplateId: '',
          variableIds: [],
        }
        return (item = this.addDefaultTemplate(data))
      })
    },

    /* ------------------------------------------------------------------------------------------------ */
    /* 开关 */
    switchChange(checked, record, index) {
      console.log(record, '开关项')
      this.chancelData[index].switch = checked
      /* 发送更新模板状态请求 */
      let { state, templateId, ...data } = record
      data = {
        status: checked ? 'ENABLED' : 'DISABLED',
      }
      this.updateTemplate(record.templateId, data)
        .then(() => {})
        .catch(() => {
          this.chancelData[index].switch = !checked
        })
    },
    /* tab切换 */
    tabChange(key) {
      this.tabKey = key
    },
    /* 编辑scenarioKey */
    EditKey() {
      this.isModify = true
      this.editPre = this.scenarioKey
    },
    cancelEdit() {
      this.isModify = false
      this.scenarioKey = this.editPre
    },
    /* 修改scenarioKey */
    /* 修改scenarioKey */
    modifyKey() {
      /* 发送更新业务场景请求 */
      let { scenarioKey, ...data } = this.sceneData
      this.$set(data, 'scenarioKey', this.scenarioKey)
      this.$api
        .updateScene(this.sceneId, data)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('修改成功')
            this.refresh()
          }
        })
        .catch((err) => {
          this.$message.error('修改失败')
          console.log(err, '修改错误')
        })
      this.isModify = false
    },
    /* 添加模板 */
    addTemplate() {
      this.templateData = {}
      this.templateVisible = true
    },
    /* 编辑模板 */
    editTemplate(record) {
      this.templateData = record
      this.templateVisible = true
    },
    /* 添加场景按钮 */
    addScene() {
      this.sceneData = {}
      this.sceneVisible = true
    },
    /* 删除场景按钮 */
    delScene() {
      this.delSceneVisible = true
    },
    /* 编辑场景 */
    EditScene() {
      // this.sceneData = {
      //   name: '11'
      // }
      this.sceneVisible = true
    },

    /* 删除变量 */
    delVariable(record) {
      let _this = this
      this.$confirm({
        title: '你确定要删除这条变量吗？',
        onOk() {
          /* 发送删除变量请求 */
          _this.delVariableOfScene(record.variableId)
        },
        onCancel() {},
      })
    },

    /* 删除变量 */
    delVariableOfScene(variableId) {
      this.$api
        .deleteVariableOfScene(variableId)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('删除成功')
            this.refresh()
          }
        })
        .catch((err) => {
          this.$message.error('删除失败')
        })
    },

    /* 编辑格式化设置 */
    editIndividuation(record) {
      this.individuationRecord = record
      this.individuationVisible = true
    },
    /* 添加个性化设置 */
    addIndividuation() {
      this.individuationRecord = {}
      this.individuationVisible = true
    },
    /* 删除个性化设置 */
    delIndividuation(record) {
      let _this = this
      this.$confirm({
        title: '你确定要删除这条个性化设置吗？',
        onOk() {
          _this.$api
            .deleteCustomization(record.customizationId)
            .then((res) => {
              if (res.code === 0) {
                _this.$message.success('删除成功')
                _this.refresh()
              }
            })
            .catch((err) => {
              _this.$message.error('删除失败')
              console.log(err, '删除失败')
            })
        },
        onCancel() {},
      })
    },
    /* 添加变量 */
    addVariable() {
      this.variableVisible = true
    },
  },
}
</script>

<style lang="less" scoped>
@import '@/assets/CSS/global.less';
.systemNotification {
  height: 99%;
  width: 100%;
  display: flex;
  flex-direction: columns;
  justify-content: space-between;
  .menu {
    width: 220px;
  }
  .content {
    width: calc(100% - 230px);
    border-radius: 3px;
    border: 1px #e8e8e8 solid;
    overflow: hidden;
    padding: 10px 20px 10px 20px;
    background-color: #fff;

    .content_key {
      position: relative;
      height: 40px;
      display: flex;
      align-items: center;
      background-color: #fff;
      div {
        display: flex;
        align-items: center;
        span {
          display: flex;
          align-items: center;
          // font-size: 20px;
        }
      }
    }
  }
  .content_tab {
    background-color: #fff;
    margin-top: 5px;
    .button {
      margin: 5px;
      margin-left: 10px;
    }
  }
}

.scenarioKey {
  position: relative;
  margin-right: 20px;
  margin-left: 15px;
  width: 200px;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  color: #151515;
  /* 伪类 */
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
    width: 4px;
    height: 20px;
    border-radius: 3px;
    background: #1890ff;
  }
}

.editKey {
  position: absolute;
  right: 0px;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  color: #666;
}
</style>
